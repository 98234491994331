import React, { FC } from 'react';
import GooglePlayLogo from 'assets/icons/google-play-logo.svg';
import styled from 'styled-components';
import { StoresLinks } from 'utils/constants/externalLinks';

interface GooglePlayLinkBtnProps {
  linkOverride?: string;
}

const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AppleStoreBadge = styled(GooglePlayLogo)`
  width: 10.25rem;
  height: 3rem;
`;

const GooglePlayLinkBtn: FC<GooglePlayLinkBtnProps> = ({
  linkOverride,
  ...props
}) => (
  <AppleStoreBadge
    onClick={() =>
      window.open(linkOverride ?? StoresLinks.GooglePlay, '_system')
    }
  />
);

export default GooglePlayLinkBtn;
