const getTodayDateString = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}${month}${day}`;
};

const activityLevels = {
  almost_every_day: 4,
  several_times_per_week: 3,
  several_times_per_month: 2,
  never: 1,
};

const mealsData = {
  two: 3,
  three: 3,
  four: 4,
  five: 5,
};

const resolveAvoidIngredients = (quiz_answers: any) => {
  const values = [
    quiz_answers?.exclude_products_protein_diary,
    quiz_answers?.exclude_products_fruits_vegetables,
    quiz_answers?.exclude_products_nuts_grains_breads,
  ];
  return values.join('|').split('|');
};

export const registerPayload = (code: string, quiz_answers: any) => {
  const avoidIngredients = resolveAvoidIngredients(quiz_answers);

  const userRTDBProperties = {
    weightHistory: {
      [getTodayDateString()]: quiz_answers?.current_weight?.kg,
    },
    userDetails: {
      isQuizComplete: true,
      code: code,
      startingWeight: quiz_answers?.current_weight?.kg,
      goalWeight: quiz_answers?.target_weight?.kg,
      gender: quiz_answers?.gender,
      height: quiz_answers?.height?.cm,
      activityLevel: activityLevels[quiz_answers?.exercise],
      age: quiz_answers?.age?.year,
      unitsOfMeasure: 'imperial',
      propertiesToIgnoreRaw: avoidIngredients,
      mealsADay: mealsData[quiz_answers?.meals_a_day],
    },
  };

  return userRTDBProperties;
};
